var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{staticClass:"px-6 py-6",attrs:{"fluid":""}},[_c('v-row',{staticClass:"mt-8"},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-card',{attrs:{"dark":""}},[_c('v-card-title',{staticClass:"text-h5 font-weight-regular orange black--text"},[_vm._v(" Member 1 ")]),_c('v-card-text',[_c('v-autocomplete',{attrs:{"items":_vm.items,"loading":_vm.isLoading,"search-input":_vm.searchNew,"chips":"","clearable":"","hide-details":"","hide-selected":"","item-text":"name","item-value":"id","label":"Search for a office...","return-object":""},on:{"update:searchInput":function($event){_vm.searchNew=$event},"update:search-input":function($event){_vm.searchNew=$event}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-list-item',[_c('v-list-item-title',[_vm._v(" Search for your "),_c('strong',[_vm._v("Office")])])],1)]},proxy:true},{key:"selection",fn:function(ref){
var attr = ref.attr;
var on = ref.on;
var item = ref.item;
var selected = ref.selected;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"white--text",attrs:{"input-value":selected,"color":"blue-grey"}},'v-chip',attr,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-bitcoin ")]),_c('span',{domProps:{"textContent":_vm._s(item.name)}})],1)]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-avatar',{staticClass:"text-h5 font-weight-light white--text",attrs:{"color":"indigo"}},[_vm._v(" "+_vm._s(item.name.charAt(0))+" ")]),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.name)}})],1),_c('v-list-item-action',[_c('v-icon',[_vm._v("mdi-bitcoin")])],1)]}}]),model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}})],1),_c('v-divider'),_c('v-expand-transition',[(_vm.model)?_c('v-list',{staticClass:"black lighten-3"},[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Name ")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.model.name)+" ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Type ")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.model.type)+" ")])],1)],1)],1):_vm._e()],1)],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-card',{attrs:{"dark":""}},[_c('v-card-title',{staticClass:"text-h5 font-weight-regular orange black--text"},[_vm._v(" Member 1 ")]),_c('v-card-text',[_c('v-autocomplete',{attrs:{"items":_vm.items2,"loading":_vm.isLoading2,"search-input":_vm.searchNew2,"chips":"","clearable":"","hide-details":"","hide-selected":"","item-text":"name","item-value":"id","label":"Search for a office...","return-object":""},on:{"update:searchInput":function($event){_vm.searchNew2=$event},"update:search-input":function($event){_vm.searchNew2=$event}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-list-item',[_c('v-list-item-title',[_vm._v(" Search for your "),_c('strong',[_vm._v("Office")])])],1)]},proxy:true},{key:"selection",fn:function(ref){
var attr = ref.attr;
var on = ref.on;
var item = ref.item;
var selected = ref.selected;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"white--text",attrs:{"input-value":selected,"color":"blue-grey"}},'v-chip',attr,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-bitcoin ")]),_c('span',{domProps:{"textContent":_vm._s(item.name)}})],1)]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-avatar',{staticClass:"text-h5 font-weight-light white--text",attrs:{"color":"indigo"}},[_vm._v(" "+_vm._s(item.name.charAt(0))+" ")]),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.name)}})],1),_c('v-list-item-action',[_c('v-icon',[_vm._v("mdi-bitcoin")])],1)]}}]),model:{value:(_vm.model2),callback:function ($$v) {_vm.model2=$$v},expression:"model2"}})],1),_c('v-divider'),_c('v-expand-transition',[(_vm.model2)?_c('v-list',{staticClass:"black lighten-3"},[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Name ")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.model2.name)+" ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Type ")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.model2.type)+" ")])],1)],1)],1):_vm._e()],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex justify-content-center"},[_c('v-btn',{staticClass:"btn-warning btn-lg",attrs:{"ripple":false,"icon":"","rounded":"","color":"orange","width":"52px","height":"52px"},on:{"click":_vm.combine}},[_c('v-icon',{attrs:{"size":"20"}},[_vm._v("fa fa-cog py-2")]),_vm._v(" Combine ")],1)],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }